/* ==================================================
Mobile nav display toggle:

Toggles animated display of main header nav
on small screens
===================================================*/
var $mainNav = $('.offscreen-nav'); 
var $navToggle = $('.nav-toggle');
var $body = $('body');
var navState = 'closed';

$navToggle.click(function(e) {
    e.preventDefault();

    if(navState === 'closed') {
      openNav();
    } else {
      closeNav();
    }
});

function closeNav() {
  $navToggle.removeClass('active');
  $body.removeClass('nav-overlay');
  $mainNav.removeClass('open');
  $navToggle.children('.hamburger').removeClass('active');
  Foundation.Motion.animateOut($mainNav, 'fade-out');
  navState = 'closed';
}

function openNav() {
  $navToggle.addClass('active');
  $body.addClass('nav-overlay');
  $mainNav.addClass('open');
  $navToggle.children('.hamburger').addClass('active');
  Foundation.Motion.animateIn($mainNav, 'fade-in');
  navState = 'open';
}


/* ==================================================
Active Nav:

Adds the class of 'selected' to the anchor (if any)
that matches the current path.
===================================================*/

const path = location.pathname.substring(1).split('/')[0];

if(path === 'blog') {
  $('nav a[href="/blog/"]').parent().addClass('selected');
}

if(path === 'contact') {
  $('nav a[href="/contact/"]').parent().addClass('selected');
}


/* ==================================================
Sticky Nav:

Applies class to sticky nav to add bg color
===================================================*/
// var rafTimer;
// var lastSectionId = '';
// var pageheader = document.querySelector('.page-header');

// var scrollItems = $('nav li a[href^="#"]').map(function(){
//   var item = $($(this).attr("href"));
//   if (item.length) { return item; }
// });

// window.onscroll = function (event) {
//   cancelAnimationFrame(rafTimer);
//   rafTimer = requestAnimationFrame(scrollAction);
// };

// function scrollAction() {
//   //Sticky nav
//   if (window.scrollY > pageheader.scrollHeight) {
//     document.body.classList.add('sticky');
//   } else {
//     document.body.classList.remove('sticky');
//   }
//   //Nav selected state
//   var $fromTop = $(this).scrollTop();
//   var currentSection = scrollItems.map(function(){
//     if ($(this).offset().top < $fromTop + 100) {
//       return this;
//     }
//   });

//   currentSection = currentSection[currentSection.length - 1];
//   if(currentSection) {
//     var id = currentSection[0].id;

//     if(lastSectionId != id) {
//       lastSectionId = id;
//       $('nav a').parent().removeClass('selected');
//       $(`nav a[href="#${id}"]`).parent().addClass('selected');
//     }
//   }
// }

// window.onload = scrollAction();


/* ==================================================
Sliders:

Slick carousel setup and initialization
===================================================*/

// $('.hero .slider').slick({
//   autoplay: true,
//   autoplaySpeed: 7000,
//   pauseOnHover: true,
//   draggable: false,
// });


/* ==================================================
Magnific:

Magnific is used for lightbox style popups.

.image-link is for displaying larger versions of
images from a a link.

.video-link is for displaying videos from a link.
Common providers like Youtube and Vimeo will
stop playing when window is closed.
===================================================*/

// $('.image-link').magnificPopup({ type: 'image' });
// $('.video-link').magnificPopup({ type: 'iframe' });
$('.bio-trigger').magnificPopup({
  type:'inline',
  midClick: true
});


/* ==================================================
Background image rotator:

Rotates background images behind static text
content
===================================================*/
var imgSlides = $('.slider-bg-img');

if(imgSlides) {
    function changeSlide() {
        imgSlides.each(function(index) {
            var slide = $(this);
            var slideIndex = index + 1;

            if (slide.hasClass('current')) {
                if (slideIndex >= imgSlides.length) {

                    $('.img-0').addClass('current').fadeIn('slow', function() {
                        slide.removeClass('current').fadeOut('fast');
                    });
                    return false;
                }

                $('.img-' + slideIndex).addClass('current').fadeIn('slow', function() {
                    slide.removeClass('current').fadeOut('fast');
                });
                return false;
            }
        });
    }

    setInterval(changeSlide, 6000);
}


/* ==================================================
Smooth Scrolling :

Smooth Scrolling Jquery Plugin
===================================================*/

// Select all links with hashes
$('nav a[href^="#"]').on('click',function (e) {
    e.preventDefault();
    closeNav();
    var target = this.hash;
    var $target = $(target);

    $('html, body').stop().animate({
        'scrollTop': $target.offset().top
    }, 900, 'swing', function () {
        window.location.hash = target;
    });
});

//Utilities
$(function(){
  $('html').removeClass('no-js');
});


var currentActiveHex;
var hexTileDetailsArray = $('.hex-tile-details');
var hexOffsetRow = [];
var windowWidth = $(window).width();

$('.toggle-hex-details').click(function(e){
  e.preventDefault();
  toggleVisibleHex(e);
});

function toggleVisibleHex(e) {
  var hexTileId = e.currentTarget.id;
  if(hexTileId === currentActiveHex) {
    clearHexTiles();
    return;
  }
  clearHexTiles();
  var hexTileHeight = e.currentTarget.scrollHeight;
  var clickedHexOffset = e.currentTarget.offsetParent.offsetTop;
  currentActiveHex = hexTileId;

  $('#' + hexTileId).addClass('active');

  $('.toggle-hex-details').each(function(index, value) {
    if(value.offsetParent.offsetTop == clickedHexOffset) {
      hexOffsetRow.push(value.id)
    }
  });

  appendHexDetails(hexTileId)
  $('#' + hexTileId + '-details').fadeIn(300);
  $('html, body').stop().animate({
      'scrollTop': $('#' + hexTileId).offset().top - 20 + 'px'
  }, 900, 'swing', function () {
  });
}

function appendHexDetails(id) {
  $('#' + hexOffsetRow[hexOffsetRow.length - 1]).parent().after('<div class="full-hex-row"></div>');

  hexTileDetailsArray.each(function(index, value) {
    if(id === value.dataset.parentTrigger) {
      $('.full-hex-row').append($(this));
    }
  });

}

function clearHexTiles() {
  currentActiveHex = '';
  $('.toggle-hex-details').removeClass('active');
  $('.full-hex-row').slideUp();
  $('.hex-tile-details').hide();
  $('.full-hex-row').remove();
}

$(window).resize(function() {
  if ($(window).width() != windowWidth) {
    windowWidth = $(window).width();
    clearHexTiles();
  }
});